import React, { useState, useEffect, useRef } from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Transition } from "@headlessui/react";
// import useLockBodyScroll from '../utils/useLockBodyScroll'
import { modal, modalInner, media, modalLink, close, overlayMediaWrapper } from '../styles/modal.module.scss'

const Modal = props => {

    const { className, image, children } = props;

    const [modalIsOpen, modalSetIsOpen ] = useState(false);

    const toggleImage = () => modalSetIsOpen(!modalIsOpen);

    const ref = useRef();

    let modalLinkClassName = modalLink;
    if ( className ) modalLinkClassName = [modalLink, className].join(" ");

    let modalPopupClassName = modal;

    useEffect(() => {
        const checkIfClickedOutside = e => {
    
          if (modalIsOpen && ref.current && !ref.current.contains(e.target)) {

            modalSetIsOpen(false)
    
            } else if ( modalIsOpen && ref.current && e.target.tagName !== 'IMG' ) {
                modalSetIsOpen(false)
            }

        }

    
        document.addEventListener("mousedown", checkIfClickedOutside)
    
    
        return () => {
    
          // Cleanup the event listener
    
          document.removeEventListener("mousedown", checkIfClickedOutside)
    
        }
    
      }, [modalIsOpen])

      const handleClose = () => {
        modalSetIsOpen(false);
      };
      

      useEffect(() => {
        modalIsOpen ? (
            document.documentElement.style.overflow = 'hidden'
        ) : (
            document.documentElement.style.overflow = 'unset'
        )
     }, [modalIsOpen]);

    if ( !image && !children ) return null;  

    return (<>

        <div className={modalLinkClassName} onClick={toggleImage}  onKeyPress={toggleImage} role={`button`} tabIndex={`0`}>
            { image ? (
                <GatsbyImage
                image={getImage(image?.localFile)}
                alt={image?.altText || `Image`}
                />
            ) : children ? (
                children
            ) : (
                null
            ) }
        </div>

        <Transition
                show={modalIsOpen}
                enter="transition duration-100 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition duration-150 ease-out"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={modalPopupClassName}
                >
                <div className={modalInner}>
                    <Transition.Child
                        enter={`transition ease-in-out duration-300 transform`}
                        enterFrom={`transform scale-90 opacity-0`}
                        enterTo={`transform scale-100 opacity-100`}
                        leave={`transition duration-150 ease-out`}
                        leaveFrom={`transform scale-100 opacity-100`}
                        leaveTo={`transform scale-90 opacity-0`}
                        className={media}
                        
                    >
                        <div className={overlayMediaWrapper} ref={ref}>
                            { image ? (
                                    <GatsbyImage
                                image={getImage(image?.localFile)}
                                alt={image?.altText || `Image`}
                                />
                            ) : children ? (
                                children
                            ) : (
                                null
                            ) }
                        </div>
                    </Transition.Child>

                </div>
                <button onClick={handleClose} className={close}><span>&nbsp;</span></button>
        </Transition>
        
    </>)
}

export default Modal;