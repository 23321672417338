import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Modal from './Modal'

const MaybeLink = props => {

    const { link, className, children, ...rest } = props;

    const maybeLinkBaseClassName = 'duration-200 block transform hover:skew-y-1 hover:skew-x-1 hover:scale-[0.97]';
    let maybeLinkClassName = maybeLinkBaseClassName;
    if (className) maybeLinkClassName = [maybeLinkBaseClassName, className].join(" ");

    if (! link ) return <Modal>{children}</Modal>;

    return (
        <OutboundLink className={maybeLinkClassName} href={link} {...rest}>

            { children }

        </OutboundLink>
    )
}

export default MaybeLink