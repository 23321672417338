import * as React from 'react'
import { graphql } from "gatsby"
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from "../components/Layout"
import PageWrapper from '../components/PageWrapper'
import Seo from "../components/Seo"
import { PageTitle } from '../components/PageTitles'
import { IconLogoFilled } from "../svg/LtaLogos"
import { logo, logoBounce } from "../styles/header.module.scss"
import Button from "../components/Button"
import Pagination from '../components/Pagination'
import Gallery from '../components/Gallery'
// import MasonryGallery from '../components/MasonryGallery'
// import MaybeLink from '../components/MaybeLink'


const PresentsArchive = ({ data, pageContext }) => {

    const topPosition = {
        top: 'calc(50% - 9rem/1.5)'
    }


    return (
        <Layout>
             <Seo title={`Presents`} description={`Upcoming events brought to you by Lighter Than Air.`}  />

                <PageWrapper>

                <PageTitle className={`hidden`}>LTA Presents</PageTitle>

                <Gallery galleryData={data.allWpGallery.edges} />

                {/* <MasonryGallery renderAs={`ul`} className={`p-0 -m-1.5`}>
                {
                        data.allWpGallery.edges.map(gal => {

                            const itemData = getImage(gal.node.featuredImage?.node?.localFile)

                            const hasUrl = gal.node.presentsGallerySettings.eventMediaUrl
                            let itemClassName = `w-1/2 md:w-1/3 lg:w-1/4 m-0 p-1.5`;
                            
                            let maybeUrl = null;

                            if (hasUrl) {
                                // itemClassName = [`w-1/2 md:w-1/3 lg:w-1/4 m-0 p-1.5`, clickable].join(" ");
                                maybeUrl = hasUrl;
                            }

                            return  (
                                <li className={itemClassName} key={gal.node.id}>
                                    <MaybeLink link={maybeUrl} className={`cursor-pointer`} target={`_blank`} rel={`noopener noreferrer`} title={gal.node.title}>
                                        <div>
                                            <GatsbyImage 
                                                image={itemData}
                                                layout={`fullWidth`}
                                                alt={gal.node.featuredImage.node.altText || gal.node.title}
                                            />
                                            </div>
                                    </MaybeLink>
                                </li>
                            )
                        })
                    }
                </MasonryGallery> */}
    

                <Pagination context={pageContext} />

                <div style={topPosition} className={`fixed bottom-auto right-0 left-0 m-auto z-[100] w-40 h-40 md:h-52 md:w-52 xl:w-56 xl:h-56 xl:-translate-y-12 flex flex-col justify-center items-center`}>

                    <IconLogoFilled 
                    className={`w-36 md:w-44 xl:w-48 mb-3 xl:mb-4 ${[logo, logoBounce].join(" ")}`}
                    primaryFill={`var(--text-contrast)`}
                    secondaryFill={`var(--color-primary)`} 
                    />
                    <Button className={`text-sm`} style={{padding: '0.65rem 0.75rem', color: 'var(--text-color)'}} type={`external`} href={`https://thepointofsale.com/tickets/czz221207001`}>Next Event</Button>
                </div>

                </PageWrapper>
        </Layout>
    )
}

export default PresentsArchive

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allWpGallery(
      skip: $skip,
      limit: $limit
      sort: {
        fields: date
        order: DESC
      }
      filter: { status: { eq: "publish" }, featuredImageDatabaseId: {ne: null}}
    ) {
    edges {
        node {
            id
            slug
            title 
            featuredImage {
                node {
                    altText
                    localFile {
                    childImageSharp {
                        gatsbyImageData
                    }
                    }
                }
                }
            presentsGallerySettings {
                eventMediaUrl
            }
        }
    }
  }

}
`;